body {
  margin: 0;
  padding: 0;
  background: linear-gradient(119.31deg, rgba(44, 96, 138, 0.1) 1.15%, rgba(145, 95, 255, 0.1) 100.17%);
  background-blend-mode: multiply;
  font-family: 'Gilroy';
  color: #000; }

button {
  outline: none;
  background: transparent;
  border: 0; }

section {
  padding-top: 136px;
  position: relative;
  height: 100vh;
  min-height: 600px;
  @media (max-width: $md) {
    padding-top: 75px;
    min-height: 790px; }
  @media (max-width: $sm) {
    min-height: 670px; }

  &:before, &:after {
    content: '';
    position: absolute;
    background: #725FDB;
    border-radius: 2px;
    height: 1px; }
  &:before {
    left: 0;
    top: 130px;
    width: 522px;
    @media (max-width: $md) {
      width: 70%;
      top: 115px; } }
  &:after {
    right: 0;
    bottom: 68px;
    width: 166px;
    @media (max-width: $md) {
      display: none; } }
  &:first-child {
    &:before {
      top: 166px; } } }

header, footer, section, div {
  box-sizing: border-box; }

h1, h2, h3, ul, ol, li, p {
  margin: 0;
  padding: 0; }

.wrapper {
  width: 100%;
  padding: 0 166px 60px 82px;
  @media (min-width: $xl) {
    width: 1140px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 94px); }
  @media (max-width: $lg) {
    padding: 0 40px 82px; }
  @media (max-width: $md) {
    width: 100%;
    padding: 0; } }

.title {
  font-size: 72px;
  line-height: 85px;
  @media (max-width: $xl) {
    font-size: 64px;
    line-height: 70px; }
  @media (max-width: $lg) {
    font-size: 54px;
    line-height: 64px; } }

.desc {
  font-size: 18px;
  line-height: 21px;
  padding-left: 50px;
  @media (max-width: $xl) {
    font-size: 16px;
    line-height: 20px;
    padding-left: 30px; }
  @media (max-width: $lg) {
    font-size: 14px;
    line-height: 20px; }
  @media (max-width: $md) {
    padding-left: 17px;
    padding-right: 17px;
    font-size: 14px;
    line-height: 16px; } }
