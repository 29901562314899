.hide {
  display: none!important; }

/* color Helper */
.color-grey {
  color: #c2c2c2; }

.color-white {
  color: #ffffff; }

.color-primary {
  color: $color-primary; }

.text-uppercase {
  text-transform: uppercase; }

/**
 * font-size Helper
 */
@each $sizes in 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 38, 72, 200, 130 {
  .size-#{$sizes} {
    font-size: #{$sizes}px; } }


/**
 * font-weight Helper
 */
@each $weight in 300, 400, 500, 700 {
  .weight-#{$weight} {
    font-weight: #{$weight}; } }


/**
 * Box-shadow
 */
.sh-1 {
  box-shadow: 0 0 50px rgba(28,4,10,0.1); }



/**
 * Margin and Padding Helper
 */
.m-6 {
  margin: 5rem !important; }
.mt-6 {
  margin-top: 5rem !important; }
.mr-6 {
  margin-right: 5rem !important; }
.mb-6 {
  margin-bottom: 5rem !important; }
.ml-6 {
  margin-left: 5rem !important; }


.p-6 {
  padding: 5rem !important; }
.pt-6 {
  padding-top: 5rem !important; }
.pr-6 {
  padding-right: 5rem !important; }
.pb-6 {
  padding-bottom: 5rem !important; }
.pl-6 {
  padding-left: 5rem !important; }


@media (max-width: 767px) {
  .m-6 {
    margin: 2rem !important; }
  .mt-6 {
    margin-top: 2rem !important; }
  .mr-6 {
    margin-right: 2rem !important; }
  .mb-6 {
    margin-bottom: 2rem !important; }
  .ml-6 {
    margin-left: 2rem !important; }

  .p-6 {
    padding: 2rem !important; }
  .pt-6 {
    padding-top: 2rem !important; }
  .pr-6 {
    padding-right: 2rem !important; }
  .pb-6 {
    padding-bottom: 2rem !important; }
  .pl-6 {
    padding-left: 2rem !important; } }

