header.header {
  padding: 43px 40px 10px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #f0effa;
  z-index: 10;
  @media (max-width: $md) {
    padding: 21px 23px; }
  .header-logo {
    @media (max-width: $md) {
      width: 170px;
      img {
        width: 100%; } } } }

.next {
  position: absolute;
  width: 55px;
  height: 55px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7px;
  .next-arrow {
    position: absolute;
    width: 53px;
    height: 3px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
    &:first-child {
      animation: move 3s ease-out 1s infinite; }
    &:nth-child(2) {
      animation: move 3s ease-out 2s infinite; }
    &:before, &:after {
      content: ' ';
      position: absolute;
      top: 0;
      height: 100%;
      width: 51%;
      background: #3DAE2B; }
    &:before {
      left: 0;
      transform: skew(0deg, 37deg); }
    &:after {
      right: 0;
      width: 50%;
      transform: skew(0deg, -37deg); } } }

@keyframes move {
  25% {
    opacity: 1; }
  33% {
    opacity: 1;
    transform: translateY(30px); }
  67% {
    opacity: 1;
    transform: translateY(40px); }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5); } }

nav.nav {
  position: fixed;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: $md) {
    left: auto;
    right: 17px; }
  .nav-item {
    width: 10px;
    height: 10px;
    background: #000;
    margin-bottom: 15px;
    position: relative; }
  .nav-item.is-active {
    &:before {
      content: '';
      position: absolute;
      left: -3px;
      top: -3px;
      width: 14px;
      height: 14px;
      background: transparent;
      border: 1px solid #AA85FE; } } }

section.home {
  background: url("../images/svg/credit-card-first-page.svg") no-repeat;
  background-position: right;
  background-size: 45% 442px;
  @media (max-width: $lg) {
    background-size: 55%;
    background-position: right; }
  @media (max-width: $md) {
    background-position: center 80%;
    background-size: 80%;
    &:before, &:after {
      display: none; } }
  &:after {
    display: none; }

  .home-title {
    @media (max-width: $md) {
      display: none; } }

  .home-subtitle {
    padding-bottom: 10px;
    @media (max-width: $md) {
      display: none; } }

  .home-desc {
    max-width: 380px;
    padding-bottom: 31px;
    @media (max-width: $lg) {
      max-width: 280px;
      padding-bottom: 15px; }
    @media (max-width: $md) {
      max-width: 100%; } }

  form {
    padding-left: 50px;
    @media (max-width: $xl) {
      padding-left: 30px; }
    @media (max-width: $md) {
      padding: 0;
      margin-left: 17px;
      position: relative;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 45px;
        height: 42px;
        padding: 0;
        z-index: 10;
        font-size: 0;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          background: url("../images/svg/right-arrow.svg") no-repeat;
          background-size: 100%;
          width: 50%;
          height: 23px; } } } } }

section.about {
  background-position: 90% center;
  background-size: 440px;
  padding-right: 95px;
  background-repeat: no-repeat;
  background-image: url("../images/svg/credit-card-second-page.svg");
  @media (max-width: $lg) {
    background-size: 50%;
    background-position: center 85%; }
  @media (max-width: $md) {
    padding-right: 0;
    background-size: 100%;
    background-position: center 85%; }
  @media (min-width: $xl) {
    background-position: right;
    background-size: 35% 442px; }

  .about-subtitle {
    padding-bottom: 10px;
    @media (max-width: $md) {
      font-size: 30px;
      line-height: 35px;
      padding-left: 17px; } }

  .about-desc {
    max-width: 402px;
    @media (max-width: $lg) {
      max-width: 100%; } } }

section.take {

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $lg) {
      flex-wrap: wrap; } }

  .take-left, .take-right {
    @media (max-width: $lg) {
      width: 100%; } }

  .take-left {
    @media (max-width: $md) {
      padding-top: 40px;
      margin-bottom: 25px; } }

  .take-right {
    @media (max-width: $md) {
      width: 80%;
      margin: 0 auto; } }

  form {
    @media (max-width: $lg) {
      margin: 40px auto 0; } }

  label {
    @media (max-width: $md) {
      &:first-child {
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; }
      &:nth-child(2) {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; } } }

  button {
    @media (max-width: $md) {
      margin: 40px 0; } }

  .take-desc {
    padding-top: 15px;
    max-width: 425px;
    padding-bottom: 30px;
    @media (max-width: $md) {
      display: inline-block;
      max-width: 100%; } }

  .take-img {
    width: 385px;
    height: auto;
    padding-left: 210px;
    @media (max-width: $xl) {
      padding-left: 20%; }
    @media (max-width: $md) {
      padding: 0;
      width: 80%;
      margin: 0 auto;
      display: block; } } }

