//----------------------------------------------------/
// Variables
//----------------------------------------------------/

// Fonts
$font-family-title:               inherit !default;
$font-icon-fontawesome:           FontAwesome !default;

// Colors
$blue:                            #50a1ff !default; //0facf3
$indigo:                          #6610f2 !default;
$purple:                          #926dde !default;
$pink:                            #e83e8c !default;
$red:                             #ff4954 !default;
$orange:                          #ffbe00 !default;
$yellow:                          #ffba00 !default;
$green:                           #3cd458 !default;
$teal:                            #20c997 !default;
$cyan:                            #17a2b8 !default;

// Context colors
$color-primary:                   #D4B293 !default;
$color-secondary:                 #e9ecf0 !default; //e4e7ea
$color-success:                   $green !default;
$color-info:                      $purple !default;
$color-warning:                   $yellow !default;
$color-danger:                    $red !default;
$color-light:                     #f8f9fa !default;
$color-dark:                      #191919 !default;

// Text colors
$color-text:                      #1c040a !default;

// Width
$sm:                               576px;
$md:                               720px;
$lg:                               992px;
$xl:                               1140px;



