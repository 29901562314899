form {
  max-width: 300px;
  @media (max-width: $lg) {
    max-width: 250px; }
  @media (max-width: $md) {
    max-width: 100%; } }

label {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: $lg) {
    margin-bottom: 20px; }
  @media (max-width: $md) {
    margin-bottom: 0;
    &:first-child {
      input {
        border-bottom: 1px solid #964EE2; } } }
  span {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 5px;
    @media (max-width: $lg) {
      font-size: 14px;
      padding-bottom: 0; }
    @media (max-width: $md) {
      display: none; } }
  input {
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 21px;
    border: 2px solid #964EE2;
    padding: 10px 40px 10px 13px;
    background: transparent;
    @media (max-width: $lg) {
      padding: 5px 40px 5px 10px;
      font-size: 14px; }
    @media (max-width: $md) {
      padding: 14px 8px;
      border: none; } } }

button {
  width: 100%;
  background: linear-gradient(90deg, #3DAE2B 0%, #048172 100%);
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  padding: 8px 20px; }
